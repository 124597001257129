body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.iconimage {
  opacity: 0.5;
  height: 50px;
}

.iconimage:hover {
  opacity: 1;
}

.lightboxIcon {
  opacity: 0.6;
  border: 1px solid gray;
  height: 130px;
  padding:3px;
}

.lightboxIcon:hover {
  opacity: 1;
}

div:has(> .lightboxIcon) { 
  float: left;
  padding: 10px;
 }