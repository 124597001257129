body {
  font-family: "Raleway", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

h2 {
  font-size: 1.5rem;
  color: #cc6600;
}

h5 {
  color: #cc6600;
  font-weight: bold;
}

.mt-100 {
  margin-top: 100px;
}

.App-content { 
  text-align: left;
  font-size: 1.1em;
  line-height: 1.5em;
  font-weight: 400;
  color: #000000;
}

.App-logo {
  position: relative;
  width: 150px;
  text-align: left;
}

.App-front {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.section-description {
  margin-bottom: 0rem;
}


.App-footer {
  border-bottom: 1px solid #ddd;
  background-color: #2c2c2c;
  background-size: cover;
  color: #fff;
}

.overlay{
  position:absolute;
  width: 100%;
  height: 100%;
  z-index:0;
}
.content{
  position:relative;
  z-index:1;
}

.accordion-button:not(.collapsed) {
  background-color: #ffffff !important;
}

.nav-item-active {
  font-weight: bold;
}

.descriptionModal {
  width: 90vw;    /* Occupy the 70% of the screen width */
  max-width: 90vw;
}

a {
  color: #cc6600;
}

small > a {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, .5) !important;
}